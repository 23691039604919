/* eslint-disable react/jsx-props-no-spreading */
import type { AppProps } from 'next/app';
import { Router } from 'next/router';
import { Provider } from 'next-auth/client';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import MatomoTracker from '@datapunt/matomo-tracker-js';
import 'antd/dist/antd.css';
import 'nprogress/nprogress.css';

if (typeof window !== 'undefined') {
  const tracker = new MatomoTracker({ urlBase: 'https://ma.matomo.cloud/', siteId: parseInt(`${process.env.NEXT_PUBLIC_MATOMO_CONFIG}`, 10) });
  tracker.trackPageView();
  Router.events.on('routeChangeComplete', () => { tracker.trackPageView(); });
}

const stripePromise = loadStripe(`${process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY}`);

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Elements stripe={stripePromise}>
      <Provider>
        <main>
          <Component {...pageProps} />
        </main>
      </Provider>
    </Elements>
  );
}

// import '../styles/globals.css'
// import type { AppProps } from 'next/app'
//
// function MyApp({ Component, pageProps }: AppProps) {
//   return <Component {...pageProps} />
// }
//
// export default MyApp
